import { useEffect } from "react";
import { useQuery } from "~hooks/useQuery";
import {
  EventAccessStatus,
  GetEventAndReleaseForCustomerDocument,
} from "~graphql/typed-document-nodes";
import { getError, showToast } from "~lib";
import { useRouter } from "next/router";
import { ToastType } from "react-toastify";
import * as actions from "~features/nonseated-reservation/store/actions";
import { setLocale, setTimezone } from "flicket-ui";

type QueryParams = {
  eventId?: string;
  release?: string;
  slug?: string;
};

export const useEventAccessControl = (userEventId?: string) => {
  const router = useRouter();

  const query = router.query as QueryParams;

  const eventId = query.eventId ?? userEventId;
  const releaseId = query.release;

  const { isLoading, data, error, isValidating } = useQuery(
    eventId ? GetEventAndReleaseForCustomerDocument : null,
    {
      input: { eventId, releaseId, releaseSlug: query.slug },
    },
    {
      onSuccess: (data) => {
        const { event, release } = data.getEventAndReleaseForCustomer;
        actions.updateTrackingContext({
          id: event.id,
          name: event.title,
          releaseId: release?.id,
          releaseName: release?.name,
        });

        setLocale(event.venue.locale);
        setTimezone(event.venue.timezone);
      },
    }
  );

  const { event, release, isAdmin, accessType } =
    data?.getEventAndReleaseForCustomer ?? {};

  useEffect(() => {
    if (!eventId) return;

    if (getError(error, "graphQL") === EventAccessStatus.Denied) {
      void router.push("/");
      void showToast(
        "This event is not public. Admin only access.",
        ToastType.ERROR
      );
    }
    if (getError(error, "graphQL") === EventAccessStatus.EventEnded) {
      void router.push("/");
      void showToast("This event has finished.", ToastType.ERROR);
    }
  }, [eventId, error]);

  return {
    isLoading,
    release,
    isAdmin,
    event,
    error,
    isValidating,
    accessType,
  };
};
